import { Image, Segment, Dimmer, Loader } from "semantic-ui-react";
import languageMap from "../../tools/languageMap";
import React from "react";

function PlaceHolderLoader({ type, language }) {
  const placeHolderImageSource =
    process.env.PUBLIC_URL + "/short-paragraph.png";

  const placeHolderImage = (
    <Image
      width="100%"
      height="50px"
      fetchpriority="high"
      alt="Image of an empty placeholder"
      src={placeHolderImageSource}
    />
  );

  return (
    <Segment className="loader-segment">
      <Dimmer inline="centered" size="huge" active inverted>
        <Loader inverted>{languageMap["pageLoading"][language]}</Loader>
      </Dimmer>

      {type === "single" ? (
        placeHolderImage
      ) : (
        <>
          {placeHolderImage}
          <br />
          {placeHolderImage}
        </>
      )}
    </Segment>
  );
}

export default PlaceHolderLoader;

const languageMap = {
  submit: {
    fr: "envoyer",
    nl: "verzenden",
  },
  save: {
    fr: "enregistrer",
    nl: "opslaan",
  },
  formHasBeenSaved: {
    fr: "Le questionnaire a été enregistré",
    nl: "De form is opgeslagen",
  },
  formHasBeenSubmitted: {
    fr: "Le questionnaire a été finalisé",
    nl: "De form is gefinaliseerd",
  },
  invalidForm: {
    fr: "Le questionnaire n'est pas rempli correctement",
    nl: "De form is niet correct ingevuld",
  },
  errorHasOccured: {
    fr: "Une erreur s'est produite",
    nl: "Een fout heeft zich voorgedaan",
  },
  errorListTitle: {
    fr: "Erreurs",
    nl: "fouten",
  },
  noChange: {
    fr: "Aucun changement a été détecté",
    nl: "Er is geen verandering gedetecteerd",
  },
  sessionExpiredTitle: {
    fr: "La session est expirée.",
    nl: "De sessie is verlopen.",
  },
  sessionExpiredContent: {
    fr: "La session est expirée. Veuillez relancer l'application",
    nl: "De huidige sessie is verlopen. Gelieve de applicatie te herstarten",
  },
  noSession: {
    fr: "Aucune session est active. Veuillez relancer l'application",
    nl: "Er is geen actieve sessie gevonden. Gelieve de applicatie te herstarten",
  },
  pressOKToClose: {
    fr: "Appuyer OK pour fermer l'application",
    nl: "Druk op OK om de applicatie te sluiten",
  },
  appCanBeClosed: {
    fr: "Vous pouvez fermer l'application",
    nl: "U mag de applicatie sluiten",
  },
  patientInfo: {
    fr: "Dossiers patients",
    nl: "Patiënt gegevens",
  },
  vaccines: {
    fr: "Vaccins: gestion des pop-up",
    nl: "Vaccins: afhandeling pop-up",
  },
  riskFactors: {
    fr: "Facteurs de risque",
    nl: "Risicofactoren",
  },
  medicationDeliveries: {
    fr: "Épisodes médicamenteux",
    nl: "Medicatie-afleveringen",
  },
  firstName: {
    fr: "Prénom",
    nl: "Voornaam",
  },
  lastName: {
    fr: "Nom de famille",
    nl: "Achternaam",
  },
  patientINSZ: {
    fr: "Numéro INSZ patient",
    nl: "INSZ-nummer patiënt",
  },
  age: {
    fr: "âge",
    nl: "Leeftijd",
  },
  deliveryDate: {
    fr: "La date de délivrance",
    nl: "Afleveringsdatum",
  },
  medicationName: {
    fr: "Nom du médicament",
    nl: "Naam medicatie",
  },
  infoButtonPopupContent: {
    fr: "Cliqez sur ce bouton afin d’arriver sur le site web de FarmaFlux. Vous y trouverez plus d’info sur Popvax.",
    nl: "Klik op deze knop om naar de website van Farmaflux te gaan. Lees hier meer over Popvax.",
  },
  bugButtonPopupContent: {
    fr: "Cliquez sur ce bouton afin de signaler un problème technique dans l’e-form. Un email sera généré dans votre inbox. Ajoutez y l’information technique qui est générée par le bouton bleu.",
    nl: "Klik op deze knop om een technisch probleem in de e-form te melden. Een e-mail in je inbox zal gegenereerd worden. Voeg de technische informatie toe die met de blauwe knop gegenereerd wordt.",
  },
  downloadButtonPopupContent: {
    fr: "Cliquez sur ce bouton afin de télécharger l’information technique qui doit être ajoutée dans l’email pour signaler un problème technique dans l’e-form.",
    nl: "Klik op deze knop om de technische informatie te downloaden die bij de e-mail moet worden gevoegd om een technisch probleem in de e-form te melden.",
  },
  problemPopvaxApplication: {
    fr: "Problem Popvax application",
    nl: "Probleem Popvax applicatie",
  },
  technicalInfo: {
    fr: "technique_log_",
    nl: "technische_log_",
  },
  fluVaccine: {
    fr: "Vaccin grippal",
    nl: "Griep vaccin",
  },
  covidVaccine: {
    fr: "Vaccin covid",
    nl: "Covid vaccin",
  },
  popupHandling: {
    fr: "Gestion des popups",
    nl: "Afhandeling pop-up",
  },
  notRecommendedMessageHeader: {
    fr: "Vaccin non conseillé",
    nl: "Vaccin niet aangeraden",
  },
  notRecommendedMessageBody: {
    fr: "Pour ce patient le vaccin n'est pas conseillé.",
    nl: "Voor deze patiënt wordt het vaccin niet aangeraden.",
  },
  infoMessageHeaderFlu: {
    fr: "Info grippe",
    nl: "Info griep",
  },
  infoMessageBodyFlu: {
    fr: "Il est conseillé à tous les cohabitants de personnes à risque de se faire vacciner.",
    nl: "Alle inwonenden van risicopersonen worden aangeraden zich te laten vaccineren.",
  },
  infoMessageHeaderCovid: {
    fr: "Info covid",
    nl: "Info covid",
  },
  infoMessageBodyCovid: {
    fr: "ll est recommandé à tous les cohabitants de personnes à haut risque immunodéprimées de se faire vacciner.",
    nl: "Alle inwonenden van immuungecompromitteerde risicopersonen worden aangeraden zich te laten vaccineren.",
  },
  savedAnswerMessageHeader: {
    fr: "Réponse sauvegardée!",
    nl: "Antwoord opgeslagen!",
  },
  savedAnswerMessageBody: {
    fr: "Votre réponse a été envoyée et sauvegardée.",
    nl: "Je antwoord is reeds verstuurd en opgeslagen.",
  },
  noContractMessageHeader: {
    fr: "Contrat manquant!",
    nl: "Contract ontbreekt!",
  },
  noContractMessageBodyCovid: {
    fr: "Vous n’avez pas de contrat valable pour la Covid.",
    nl: "Je hebt geen geldig contract voor covid.",
  },
  noContractMessageBodyFlu: {
    fr: "Vous n’avez pas de contrat valable pour la grippe.",
    nl: "Je hebt geen geldig contract voor griep.",
  },
  vaccinatedMessageHeaderCovid: {
    fr: "Plus de sensibilisation",
    nl: "Geen sensibilisering",
  },
  vaccinatedMessageBodyCovid: {
    fr: "Plus de sensibilisation nécessaire à la Covid-19.",
    nl: "Geen sensibilisering meer nodig voor Covid-19.",
  },
  vaccinatedMessageHeaderFlu: {
    fr: "Plus de sensibilisation",
    nl: "Geen sensibilisering",
  },
  vaccinatedMessageBodyFlu: {
    fr: "Plus de sensibilisation nécessaire à la grippe.",
    nl: "Geen sensibilisering meer nodig voor griep.",
  },
  pageLoading: {
    fr: "Page de chargement...",
    nl: "Pagina wordt geladen...",
  },
  noDeliveriesTitle: {
    fr: "Pas de délivrances",
    nl: "Geen afleveringen",
  },
  noDeliveriesBody: {
    fr: "Aucune délivrance de médication a été trouvée.",
    nl: "Er werden geen medicatie-afleveringen gevonden.",
  },
  okButton: {
    fr: "Ok",
    nl: "Oké",
  },
  formSubmittedHeader: {
    fr: "Formulaire envoyé!",
    nl: "Formulier verzonden!",
  },
  formSubmittedContent: {
    fr: "Votre formulaire a été envoyé avec succès.",
    nl: "Het ingevulde formulier is succesvol verzonden. ",
  },
  formSubmitErrorHeader: {
    fr: "Une erreur s'est produite!",
    nl: "Er heeft zich een fout voorgedaan!",
  },
};

export default languageMap;

import React from "react";
import { Button, Popup } from "semantic-ui-react";
import languageMap from "../../tools/languageMap";
export const DownloadButton = ({
  disabled,
  loading,
  language,
  downloadName,
  dataType,
  encodeUriComponent,
}) => {
  return (
    <Popup
      trigger={
        <Button
          style={{ float: "right" }}
          color="blue"
          size="medium"
          circular
          icon="download"
          loading={loading}
          disabled={disabled}
          href={`data:${dataType};charset=utf-8,${encodeURIComponent(
            encodeUriComponent
          )}`}
          download={downloadName}
          aria-label="Download button"
        />
      }
      content={languageMap["downloadButtonPopupContent"][language]}
      position="bottom center"
    />
  );
};

import { useEffect, useState } from 'react';

function useFhirSessionStorage() {
    const [ ehr, setEhr ] = useState();
    const [ iss, setIss ] = useState();
    const [ launch, setLaunch ] = useState();
    const [ mode, setMode ] = useState();

    useEffect(() => {
        setEhr(sessionStorage.getItem('ehr'));
        setIss(sessionStorage.getItem('iss'));
        setLaunch(sessionStorage.getItem('launch'));
        setMode(sessionStorage.getItem('mode'));
    }, []);

    return { ehr, iss, launch, mode };
}

export default useFhirSessionStorage;
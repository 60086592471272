import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  data: undefined,
  currentDate: "",
  currentTime: "",
  formattedCurrentDate: "",
  formattedCurrentTime: "",
  openCard: false,
  loading: true,
  submitting: false,
  sguid: "",
  apbNumber: "",
  lastRequest: undefined,
  openModal: false,
  modalType: "",
  modalHeader: "",
  modalContent: "",
  patientInfoTitle: "",
  patientInfo: {},
  riskFactorsTitle: "",
  riskFactors: {},
  vaccinesTitle: "",
  fluVaccineUnderTitle: "",
  fluVaccineOptions: {},
  fluVaccineActiveDisplay: false,
  fluSubmitted: false,
  fluVaccinated: false,
  covidVaccineUnderTitle: "",
  covidVaccineOptions: {},
  covidVaccineActiveDisplay: false,
  covidSubmitted: false,
  covidVaccinated: false,
  dispensations: {},
  dispensationsTitle: "",
  covidContract: true,
  fluContract: true,
};

export const appSlice = createSlice({
  name: "patientInfo",
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      const newState = { ...state };
      const data = _.cloneDeep(
        action.payload.data.launchinfo.launchinfo.popvaxdata
      );

      newState.covidContract = data.COVID19SENS;
      newState.fluContract = data.FLUSENS;

      newState.data = data;

      return newState;
    },
    updateData: (state, action) => {
      const newState = { ...state };
      newState.data = _.cloneDeep(action.payload);
      return newState;
    },
    fetchDateAndTime: (state, action) => {
      const newState = { ...state };
      const today = new Date();

      newState.currentDate = `${today.getDate()}${
        today.getMonth() + 1
      }${today.getFullYear()}`;

      newState.currentTime = `${today.getHours()}${today.getMinutes()}`;

      newState.formattedCurrentDate = `${today.getDate()}/${
        today.getMonth() + 1
      }/${today.getFullYear()}`;

      newState.formattedCurrentTime = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

      return newState;
    },
    logRequest: (state, action) => {
      const newState = { ...state };
      newState.lastRequest = action.payload;
      return newState;
    },
    toggleLoading: (state, action) => {
      const newState = { ...state };
      newState.loading = !action.payload;
      return newState;
    },
    setSubmitting: (state, action) => {
      const newState = { ...state };
      newState.submitting = action.payload;
      return newState;
    },
    toggleCard: (state, action) => {
      const newState = { ...state };
      newState.openCard = !action.payload;
      return newState;
    },
    openModal: (state, action) => {
      const newState = { ...state };
      const modalInfo = action.payload;
      newState.openModal = true;
      newState.modalType = modalInfo.modalType;
      newState.modalHeader = modalInfo.modalHeader;
      newState.modalContent = modalInfo.modalContent;
      return newState;
    },
    closeModal: (state, action) => {
      const newState = { ...state };
      newState.openModal = false;
      return newState;
    },
    fetchGeneralInfo: (state, action) => {
      const newState = { ...state };
      const data = action.payload.data;

      if (data.hasOwnProperty("info") && data.info) {
        newState.sguid = data.info.sguid;
        newState.apbNumber = data.info.apbNumber;
        return newState;
      }

      return state;
    },
    fetchPatientInfo: (state, action) => {
      const newState = { ...state };
      const data = action.payload.data;
      const language = action.payload.language;

      if (data.hasOwnProperty("item") && data.item) {
        const patientInfo = data.item.find(
          (object) => object && object.linkId === "PatientInfo"
        );
        if (patientInfo) {
          newState.patientInfo = patientInfo.item;
          newState.patientInfoTitle =
            language === "nl" ? patientInfo.textNl : patientInfo.textFr;

          return newState;
        }
      }

      return state;
    },
    fetchRiskFactors: (state, action) => {
      const newState = { ...state };
      const data = action.payload.data;
      const language = action.payload.language;

      if (data.hasOwnProperty("item") && data.item) {
        const riskFactors = data.item.find(
          (object) => object && object.linkId === "riskfactors"
        );
        if (riskFactors) {
          newState.riskFactorsTitle =
            language === "nl" ? riskFactors.textNl : riskFactors.textFr;
          newState.riskFactors = riskFactors.item;

          return newState;
        }
      }

      return state;
    },
    fetchVaccineInfo: (state, action) => {
      const newState = { ...state };
      const data = action.payload.data;
      const linkId = action.payload.linkId;
      const language = action.payload.language;

      const vaccinesObject = data.item.find(
        (object) => object.linkId === "vaccines"
      );

      newState.vaccinesTitle =
        language === "nl" ? vaccinesObject.textNl : vaccinesObject.textFr;

      const itemObject = vaccinesObject.item.find(
        (item) => item.linkId === linkId
      );

      if (linkId === "fluvaccin") {
        newState.fluVaccineActiveDisplay = itemObject.activeDisplay;
        newState.fluSubmitted = itemObject.submitted;
        newState.fluVaccinated = itemObject.vaccinated;
        newState.fluVaccineUnderTitle =
          language === "nl" ? itemObject.textNl : itemObject.textFr;
        newState.fluVaccineOptions = itemObject.answerOption;

        return newState;
      }

      if (linkId === "covid19vaccin") {
        newState.covidVaccineActiveDisplay = itemObject.activeDisplay;
        newState.covidSubmitted = itemObject.submitted;
        newState.covidVaccinated = itemObject.vaccinated;
        newState.covidVaccineUnderTitle =
          language === "nl" ? itemObject.textNl : itemObject.textFr;
        newState.covidVaccineOptions = itemObject.answerOption;

        return newState;
      }

      return state;
    },
    fetchDispensations: (state, action) => {
      const newState = { ...state };
      const data = action.payload.data;
      const language = action.payload.language;

      if (data.hasOwnProperty("item") && data.item) {
        const dispensations = data.item.find(
          (object) => object && object.linkId === "dispensations"
        );
        if (dispensations) {
          newState.dispensationsTitle =
            language === "nl" ? dispensations.textNl : dispensations.textFr;
          newState.dispensations = dispensations.item;

          return newState;
        }
      }

      return state;
    },
    editFluVaccineOption: (state, action) => {
      const newState = { ...state };
      const newData = _.cloneDeep(action.payload.data);
      const cnkCode = action.payload.cnkCode;

      const vaccinesObject = newData.item.find(
        (object) => object.linkId === "vaccines"
      );

      const fluVaccineObject = vaccinesObject.item.find(
        (item) => item.linkId === "fluvaccin"
      );

      const clearedFluVaccineOptions = fluVaccineObject.answerOption.map(
        (item) => {
          item.valueCoding.selected = false;
          return item;
        }
      );

      const fluVaccineSelectedOption = clearedFluVaccineOptions.find(
        (item) => item.valueCoding.code === cnkCode
      );

      fluVaccineSelectedOption.valueCoding.selected = true;

      console.log("data after flu onChange", newData);

      newState.data = newData;
      return newState;
    },
    editCovidVaccineOption: (state, action) => {
      const newState = { ...state };
      const newData = _.cloneDeep(action.payload.data);
      const cnkCode = action.payload.cnkCode;

      const vaccinesObject = newData.item.find(
        (object) => object.linkId === "vaccines"
      );

      const covidVaccineObject = vaccinesObject.item.find(
        (item) => item.linkId === "covid19vaccin"
      );

      const clearedCovidVaccineOptions = covidVaccineObject.answerOption.map(
        (item) => {
          item.valueCoding.selected = false;
          return item;
        }
      );

      const covidVaccineSelectedOption = clearedCovidVaccineOptions.find(
        (item) => item.valueCoding.code === cnkCode
      );

      covidVaccineSelectedOption.valueCoding.selected = true;

      console.log("data after covid onChange", newData);

      newState.data = newData;
      return newState;
    },
  },
});

export const {
  setInitialState,
  updateData,
  fetchDateAndTime,
  toggleLoading,
  setSubmitting,
  toggleCard,
  logRequest,
  openModal,
  closeModal,
  fetchGeneralInfo,
  fetchPatientInfo,
  fetchRiskFactors,
  fetchVaccineInfo,
  fetchDispensations,
  editFluVaccineOption,
  editCovidVaccineOption,
} = appSlice.actions;
export default appSlice.reducer;

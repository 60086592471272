import React from "react";
import { Button, Popup } from "semantic-ui-react";
import languageMap from "../../tools/languageMap";
export const BugButton = ({
  subject,
  appName,
  dguid,
  sguid,
  apbNumber,
  activeTab,
  currentDate,
  currentTime,
  disabled,
  loading,
  language,
}) => {
  const emailNL = `
          mailto:forms.support@farmaflux.be 
          ?subject=${subject}
          &body=
          Beste, %0D%0A %0D%0A 
          Er is een probleem met de ${appName} applicatie. %0D%0A %0D%0A 

          ${dguid ? `De dguid is: %20 ${dguid} %0D%0A ` : ""}
          ${sguid ? `De sguid is: %20 ${sguid} %0D%0A ` : ""}
          ${
            activeTab
              ? `Mijn huidig actieve tab is: %20 ${activeTab.order} %20 (${activeTab.display}) %0D%0A`
              : ""
          }
          Mijn APB nummer: ${apbNumber}  %0D%0A %0D%0A 
          Datum en tijdstip: %20 ${currentDate} %20 ${currentTime} %0D%0A %0D%0A 
          
          Uitleg: ... %0D%0A %0D%0A 
          
          Met vriendelijke groeten, %0D%0A 
          ...`;

  const emailFR = `
          mailto:forms.support@farmaflux.be
          ?subject=${subject}
          &body=
          Bonjour, %0D%0A %0D%0A
          Un problème est survenu dans l’application ${appName}. %0D%0A %0D%0A
          
          ${dguid ? `Le dguid est: %20 ${dguid} %0D%0A` : ""}
          ${sguid ? `Le sguid est: %20 ${sguid} %0D%0A` : ""}
          ${
            activeTab
              ? `L’onglet concerné est: %20 ${activeTab.order} %20 (${activeTab.display}) %0D%0A`
              : ""
          }
          Mon numéro APB : ${apbNumber} %0D%0A %0D%0A
          Date et heure : %20 ${currentDate} %20 ${currentTime} %0D%0A %0D%0A
          
          Explication : ... %0D%0A %0D%0A
          
          Cordialement, %0D%0A
          ...`;

  return (
    <Popup
      trigger={
        <Button
          style={{ float: "right" }}
          color="orange"
          size="medium"
          circular
          icon="bug"
          loading={loading}
          disabled={disabled}
          onClick={(e) => {
            window.location.href = language === "nl" ? emailNL : emailFR;
            e.preventDefault();
          }}
          aria-label="Bug button"
        />
      }
      content={languageMap["bugButtonPopupContent"][language]}
      position="bottom center"
    />
  );
};

import "semantic-ui-css/semantic.min.css";

import React, { useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Icon,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { List, ListDivider, ListItem, Radio, RadioGroup } from "@mui/joy";
import "@fontsource/inter";

import { nanoid } from "nanoid";

import axios from "axios";
import axiosRetry from "axios-retry";

import ResponsivePieChart from "./components/PieChart/ResponsivePieChart";
import Navbar from "./components/Navbar/Navbar";
import PlaceHolderLoader from "./components/PlaceHolderLoader/PlaceHolderLoader";
import Footer from "./components/Footer/Footer";
import { BugButton } from "./components/BugButton";
import { DownloadButton } from "./components/DownloadButton";
import { InfoButton } from "./components/InfoButton";
import FFModal from "./components/FFModal/FFModal";
import DynamicMessage from "./components/DynamicMessage/DynamicMessage";

import "./App.css";

import _ from "lodash";

import useFhirSessionStorage from "./hooks/useFhirSessionStorage";
import useBearer from "./hooks/useBearer";

import languageMap from "./tools/languageMap";

import { useDispatch, useSelector } from "react-redux";
import {
  editCovidVaccineOption,
  editFluVaccineOption,
  fetchDateAndTime,
  fetchDispensations,
  fetchGeneralInfo,
  fetchPatientInfo,
  fetchRiskFactors,
  fetchVaccineInfo,
  logRequest,
  openModal,
  setInitialState,
  setSubmitting,
  toggleCard,
  toggleLoading,
  updateData,
} from "./features/app/appSlice";

export const App = () => {
  const { accessToken, language } = useBearer();
  const { ehr, launch } = useFhirSessionStorage();

  const dispatch = useDispatch();

  const data = useSelector((state) => state.data);

  const currentDate = useSelector((state) => state.currentDate);
  const currentTime = useSelector((state) => state.currentTime);
  const formattedCurrentDate = useSelector(
    (state) => state.formattedCurrentDate
  );
  const formattedCurrentTime = useSelector(
    (state) => state.formattedCurrentTime
  );

  const fluContract = useSelector((state) => state.fluContract);
  const covidContract = useSelector((state) => state.covidContract);

  const loading = useSelector((state) => state.loading);
  const submitting = useSelector((state) => state.submitting);

  const openCard = useSelector((state) => state.openCard);

  const lastRequest = useSelector((state) => state.lastRequest);

  const sguid = useSelector((state) => state.sguid);
  const apbNumber = useSelector((state) => state.apbNumber);

  const patientInfo = useSelector((state) => state.patientInfo);
  const patientInfoTitle = useSelector((state) => state.patientInfoTitle);

  const riskFactors = useSelector((state) => state.riskFactors);
  const riskFactorsTitle = useSelector((state) => state.riskFactorsTitle);

  const vaccinesTitle = useSelector((state) => state.vaccinesTitle);

  const fluVaccineUnderTitle = useSelector(
    (state) => state.fluVaccineUnderTitle
  );
  const fluVaccineOptions = useSelector((state) => state.fluVaccineOptions);
  const fluVaccineActiveDisplay = useSelector(
    (state) => state.fluVaccineActiveDisplay
  );
  const fluSubmitted = useSelector((state) => state.fluSubmitted);
  const fluVaccinated = useSelector((state) => state.fluVaccinated);

  const covidVaccineUnderTitle = useSelector(
    (state) => state.covidVaccineUnderTitle
  );
  const covidVaccineOptions = useSelector((state) => state.covidVaccineOptions);
  const covidVaccineActiveDisplay = useSelector(
    (state) => state.covidVaccineActiveDisplay
  );
  const covidSubmitted = useSelector((state) => state.covidSubmitted);
  const covidVaccinated = useSelector((state) => state.covidVaccinated);

  const dispensationsTitle = useSelector((state) => state.dispensationsTitle);
  const dispensations = useSelector((state) => state.dispensations);

  useEffect(() => {
    let responseData;

    if (ehr && accessToken && launch) {
      const url = `${sessionStorage.getItem("ehr")}`.concat(
        `/getLaunchInfo?launch=${launch}`
      );

      axiosRetry(axios, { retries: 3 });

      axios
        .get(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          if (response && response.hasOwnProperty("data")) {
            responseData = response.data;

            if (response.status >= 200 && response.status < 300) {
              dispatch(setInitialState({ data: responseData }));

              dispatch(toggleLoading({ loading }));

              dispatch(
                logRequest({
                  action: "getLaunchInfo",
                  method: "GET",
                  path: `/getLaunchInfo?launch=${launch}`,
                  response: responseData,
                })
              );
            } else if (response.status === 498) {
              dispatch(
                openModal({
                  modalType: "error",
                  modalHeader: languageMap["sessionExpiredTitle"][language],
                  modalContent: languageMap["sessionExpiredContent"][language],
                })
              );
            } else {
              sendErrorCode(response);
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
          dispatch(
            logRequest({
              action: "getLaunchInfo",
              method: "GET",
              path: `/getLaunchInfo?launch=${launch}`,
              response: responseData,
              errorMessage: error.message,
            })
          );
          dispatch(
            openModal({
              modalType: "error",
              modalHeader: languageMap["formSubmitErrorHeader"][language],
              modalContent: error.message,
            })
          );
        });
    }
  }, [accessToken, launch, ehr, dispatch, loading, language]);

  useEffect(() => {
    dispatch(fetchDateAndTime());

    if (data) {
      dispatch(fetchGeneralInfo({ data }));

      dispatch(fetchPatientInfo({ data: data, language: language }));

      dispatch(fetchRiskFactors({ data: data, language: language }));

      dispatch(
        fetchVaccineInfo({
          data: data,
          linkId: "fluvaccin",
          language: language,
        })
      );

      dispatch(
        fetchVaccineInfo({
          data: data,
          linkId: "covid19vaccin",
          language: language,
        })
      );

      dispatch(fetchDispensations({ data: data, language: language }));
    }

    return () => {
      console.log("cleanup");
    };
  }, [data, dispatch, language]);

  const sendErrorCode = (response) => {
    throw new Error(`HTTP Error ${response.status} - ${response.statusText}`);
  };

  const handleFluOptionChange = (data, cnkCode) => {
    dispatch(editFluVaccineOption({ data, cnkCode }));
  };

  const handleCovidOptionChange = (data, cnkCode) => {
    dispatch(editCovidVaccineOption({ data, cnkCode }));
  };

  const checkIfVaccineOptionsAreSelected = (data) => {
    const newData = _.cloneDeep(data);

    const vaccinesObject = newData.item.find(
      (object) => object.linkId === "vaccines"
    );
    const fluObject = vaccinesObject.item.find(
      (item) => item.linkId === "fluvaccin"
    );
    const covidObject = vaccinesObject.item.find(
      (item) => item.linkId === "covid19vaccin"
    );

    const selectLaterOptionIfNotSelected = (
      object,
      validContract,
      optionCode
    ) => {
      const optionSelected = object.answerOption.find(
        (item) => item.valueCoding.selected === true
      );
      if (!optionSelected && validContract) {
        const laterOption = object.answerOption.find(
          (item) => item.valueCoding.code === optionCode
        );
        laterOption.valueCoding.selected = true;
      }
    };

    selectLaterOptionIfNotSelected(fluObject, newData.FLUSENS, "5521638");
    selectLaterOptionIfNotSelected(covidObject, newData.COVID19SENS, "5521448");

    return newData;
  };

  const handleSubmit = (data) => {
    if (ehr && accessToken && launch) {
      dispatch(setSubmitting(true));

      let responseData;
      let requestBody = checkIfVaccineOptionsAreSelected(data);
      const url = `${sessionStorage.getItem("ehr")}`.concat(`/postPopvaxForm`);

      axiosRetry(axios, { retries: 3 });

      axios
        .post(url, JSON.stringify(requestBody), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            responseData = response.data;

            dispatch(updateData(responseData));

            dispatch(
              logRequest({
                action: "handleSubmit",
                method: "POST",
                path: "/postPopvaxForm",
                body: requestBody,
                response: responseData,
              })
            );

            dispatch(
              openModal({
                modalType: "info",
                modalHeader: languageMap["formSubmittedHeader"][language],
                modalContent: languageMap["formSubmittedContent"][language],
              })
            );

            dispatch(setSubmitting(false));
          } else {
            sendErrorCode(response);
          }
        })
        .catch((error) => {
          console.error("error", error.message);
          dispatch(
            logRequest({
              action: "handleSubmit",
              method: "POST",
              path: "/postPopvaxForm",
              body: requestBody,
              response: responseData,
              errorMessage: error.message,
            })
          );
          dispatch(
            openModal({
              modalType: "error",
              modalHeader: languageMap["formSubmitErrorHeader"][language],
              modalContent: error.message,
            })
          );

          dispatch(setSubmitting(false));
        });
    }
  };

  const renderPatientInfoHeader = (patientInfo) => {
    if (!patientInfo) {
      return null;
    }

    return patientInfo.map((item) => {
      const headerText = language === "nl" ? item?.textNl : item?.textFr;
      return headerText ? (
        <Table.HeaderCell width={4} key={nanoid()}>
          {headerText}
        </Table.HeaderCell>
      ) : null;
    });
  };

  const renderPatientInfoBody = (patientInfo) => {
    if (!patientInfo) {
      return null;
    }

    return patientInfo.map((item) => {
      const answerObject = item?.answer?.[0];
      const answerValueString =
        language === "nl" && answerObject
          ? answerObject.valueStringNl
          : answerObject.valueStringFr;
      return answerValueString ? (
        <Table.Cell key={nanoid()}>{answerValueString}</Table.Cell>
      ) : null;
    });
  };

  const renderDispensations = (dispensations) => {
    if (
      !dispensations ||
      !dispensations.some((item) => item.hasOwnProperty("show") && item.show)
    ) {
      return (
        <div style={{ padding: "20px" }}>
          <DynamicMessage
            color="orange"
            icon="attention"
            header={languageMap["noDeliveriesTitle"][language]}
            content={languageMap["noDeliveriesBody"][language]}
          />
        </div>
      );
    }

    return dispensations.map((item) => {
      if (item.hasOwnProperty("show") && item.show) {
        const title = language === "nl" ? item.textNl : item.textFr;
        const infoText = language === "nl" ? item.infoNl : item.infoFr;

        return (
          <Card.Content key={nanoid()}>
            <h3 className="sub-title">
              {title}
              <Popup
                content={infoText}
                trigger={
                  <Icon name="info circle" className="info-icon-sub-title" />
                }
              />
            </h3>

            <Table color="green" key="green">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {language
                      ? languageMap["deliveryDate"][language]
                      : "Delivery date"}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={8}>
                    {language
                      ? languageMap["medicationName"][language]
                      : "Medication name"}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {item.item.map((item) => {
                  return (
                    <Table.Row key={nanoid()}>
                      <Table.Cell>
                        {language === "nl" ? item.textNl : item.textFr}
                      </Table.Cell>
                      <Table.Cell>
                        {language === "nl"
                          ? item.answer[0].valueStringNl
                          : item.answer[0].valueStringNl}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Card.Content>
        );
      }
      return null;
    });
  };

  function renderFooter() {
    const leftContentNL = (
      <div>
        Het formulier werd ontwikkeld door Farmaflux en de daarop betrekking
        hebbende intellectuele eigendomsrechten komen uitsluitend toe aan
        Farmaflux. Farmaflux kan geenszins aansprakelijk gehouden worden voor
        niet correcte gegevens die de apotheker zou opnemen in het formulier.
      </div>
    );

    const rightContentNL = (
      <div>
        Als u dit formulier doorstuurt, gaat u akkoord met de verwerking van de
        daarin opgenomen persoonsgegevens door Farmaflux overeenkomstig de AVG.
        Voor alle bijkomende informatie kan u de website van Farmaflux
        raadplegen.
      </div>
    );

    const leftContentFR = (
      <div>
        Le formulaire a été développé par Farmaflux et les droits de propriété
        intellectuelle y afférents appartiennent exclusivement à Farmaflux.
        Farmaflux ne peut en aucun cas être tenu responsable des données
        incorrectes que le pharmacien inclurait dans le formulaire.
      </div>
    );

    const rightContentFR = (
      <div>
        Si vous transmettez ce formulaire, vous consentez au traitement des
        données personnelles qu'il contient par Farmaflux conformément au RGPD.
        Pour toute information complémentaire, veuillez consulter le site web de
        Farmaflux.
      </div>
    );

    return language === "nl" ? (
      <Footer leftContent={leftContentNL} rightContent={rightContentNL} />
    ) : (
      <Footer leftContent={leftContentFR} rightContent={rightContentFR} />
    );
  }

  const getLastLog = () => {
    const noLogsFound = { message: ["No logs found"] };
    return JSON.stringify(lastRequest ? lastRequest : noLogsFound);
  };

  return (
    <div className="app-div">
      <Navbar
        className="navbar"
        title="Farma Forms Popvax"
        image="freddy.png"
      />

      <FFModal language={language} />

      <Container className="app-container">
        <Grid>
          <Grid.Column>
            <div className="buttons-div">
              <BugButton
                subject={languageMap["problemPopvaxApplication"][language]}
                appName={"Popvax"}
                sguid={sguid}
                apbNumber={apbNumber}
                currentDate={formattedCurrentDate}
                currentTime={formattedCurrentTime}
                disabled={false}
                language={language}
              />
              <DownloadButton
                disabled={false}
                language={language}
                downloadName={
                  languageMap["technicalInfo"][language] +
                  currentDate +
                  "_" +
                  currentTime +
                  ".txt"
                }
                dataType="text/plain"
                encodeUriComponent={getLastLog()}
              />
              <InfoButton
                url="www.farmaflux.be/nl-BE/CareInitiatives/BEGE/phm-griep~214NL"
                disabled={false}
                language={language}
              />
            </div>
          </Grid.Column>
        </Grid>
        <Card.Group>
          {loading ? (
            <PlaceHolderLoader type={"single"} language={language} />
          ) : (
            <Card fluid>
              <Card.Content>
                <Card.Header textAlign="left" className="card-header">
                  {patientInfoTitle ? patientInfoTitle : ""}
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <Table color="green" key="green">
                  <Table.Header>
                    <Table.Row>
                      {renderPatientInfoHeader(patientInfo)}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>{renderPatientInfoBody(patientInfo)}</Table.Row>
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>
          )}

          {loading ? (
            <PlaceHolderLoader type={"double"} language={language} />
          ) : (
            <Card fluid className="pie-chart-card">
              <Card.Content>
                <Card.Header className="card-header" textAlign="left">
                  {riskFactorsTitle}
                </Card.Header>
              </Card.Content>
              <Card.Content
                textAlign="center"
                className="pie-chart-card-content"
              >
                <ResponsivePieChart data={riskFactors} />
              </Card.Content>
            </Card>
          )}

          {loading ? (
            <PlaceHolderLoader type={"double"} language={language} />
          ) : (
            <Card fluid>
              <Card.Content>
                <Card.Header className="card-header" textAlign="left">
                  {vaccinesTitle}
                </Card.Header>
              </Card.Content>
              <Card.Content textAlign="center">
                <Grid>
                  <Grid.Row columns={2} centered>
                    <Grid.Column width={8}>
                      {!fluVaccineActiveDisplay &&
                      !fluSubmitted &&
                      fluContract &&
                      fluVaccinated ? (
                        <DynamicMessage
                          color="yellow"
                          icon="info circle"
                          header={
                            language
                              ? languageMap["vaccinatedMessageHeaderFlu"][
                                  language
                                ]
                              : "No sensitization"
                          }
                          content={
                            language
                              ? languageMap["vaccinatedMessageBodyFlu"][
                                  language
                                ]
                              : "No more sensitization needed for flu."
                          }
                        />
                      ) : null}
                      {!fluVaccineActiveDisplay &&
                      !fluSubmitted &&
                      !fluVaccinated &&
                      fluContract ? (
                        <DynamicMessage
                          color="orange"
                          icon="info circle"
                          header={
                            languageMap["notRecommendedMessageHeader"][language]
                          }
                          content={
                            languageMap["notRecommendedMessageBody"][language]
                          }
                        />
                      ) : null}
                      {fluVaccineActiveDisplay && fluContract ? (
                        <DynamicMessage
                          color="blue"
                          icon="info circle"
                          header={
                            language
                              ? languageMap["infoMessageHeaderFlu"][language]
                              : "Flu info"
                          }
                          content={
                            language
                              ? languageMap["infoMessageBodyFlu"][language]
                              : "All Residents of risk persons are advised to get vaccinated."
                          }
                        />
                      ) : null}
                      {!fluVaccineActiveDisplay &&
                      fluSubmitted &&
                      fluContract ? (
                        <DynamicMessage
                          color="green"
                          icon="check circle"
                          header={
                            language
                              ? languageMap["savedAnswerMessageHeader"][
                                  language
                                ]
                              : "Answer saved!"
                          }
                          content={
                            language
                              ? languageMap["savedAnswerMessageBody"][language]
                              : "Your answer has been send!"
                          }
                        />
                      ) : null}
                      {!fluContract ? (
                        <DynamicMessage
                          color="red"
                          icon="remove circle"
                          header={
                            language
                              ? languageMap["noContractMessageHeader"][language]
                              : "Contract missing!"
                          }
                          content={
                            language
                              ? languageMap["noContractMessageBodyFlu"][
                                  language
                                ]
                              : "You do not have a valid contract for flu."
                          }
                        />
                      ) : null}
                    </Grid.Column>
                    <Grid.Column width={8}>
                      {!covidVaccineActiveDisplay &&
                      !covidSubmitted &&
                      covidContract &&
                      covidVaccinated ? (
                        <DynamicMessage
                          color="yellow"
                          icon="info circle"
                          header={
                            language
                              ? languageMap["vaccinatedMessageHeaderCovid"][
                                  language
                                ]
                              : "No sensitization"
                          }
                          content={
                            language
                              ? languageMap["vaccinatedMessageBodyCovid"][
                                  language
                                ]
                              : "No more sensitization needed for Covid-19."
                          }
                        />
                      ) : null}
                      {covidVaccineActiveDisplay && covidContract ? (
                        <DynamicMessage
                          color="blue"
                          icon="info circle"
                          header={
                            language
                              ? languageMap["infoMessageHeaderCovid"][language]
                              : "Covid info"
                          }
                          content={
                            language
                              ? languageMap["infoMessageBodyCovid"][language]
                              : "All Residents of immunocompromised risk persons are advised to get vaccinated."
                          }
                        />
                      ) : null}
                      {!covidVaccineActiveDisplay &&
                      covidSubmitted &&
                      covidContract ? (
                        <DynamicMessage
                          color="green"
                          icon="check circle"
                          header={
                            language
                              ? languageMap["savedAnswerMessageHeader"][
                                  language
                                ]
                              : "Answer saved!"
                          }
                          content={
                            language
                              ? languageMap["savedAnswerMessageBody"][language]
                              : "Your answer has been send!"
                          }
                        />
                      ) : null}
                      {!covidContract ? (
                        <DynamicMessage
                          color="red"
                          icon="remove circle"
                          header={
                            language
                              ? languageMap["noContractMessageHeader"][language]
                              : "Contract missing!"
                          }
                          content={
                            language
                              ? languageMap["noContractMessageBodyCovid"][
                                  language
                                ]
                              : "You do not have a valid contract for covid."
                          }
                        />
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2} centered>
                    <Grid.Column width={8} textAlign="center">
                      <Segment
                        raised={fluVaccineActiveDisplay}
                        disabled={!fluVaccineActiveDisplay}
                        color={fluVaccineActiveDisplay ? "green" : "grey"}
                      >
                        <Icon
                          name={fluVaccineActiveDisplay ? "check" : "cancel"}
                          size="big"
                          className={
                            fluVaccineActiveDisplay ? "check-icon" : null
                          }
                        />{" "}
                        {fluVaccineUnderTitle}
                        <Divider />
                        <RadioGroup
                          className={
                            !fluVaccineActiveDisplay
                              ? "disabled-component"
                              : null
                          }
                          aria-labelledby="example-payment-channel-label"
                          overlay
                          name="example-payment-channel"
                        >
                          <List
                            className="text-align-left"
                            component="div"
                            variant="outlined"
                            orientation="vertical"
                            sx={{
                              borderRadius: "sm",
                              boxShadow: "sm",
                            }}
                          >
                            {Object.keys(fluVaccineOptions).map(
                              (keyName, index) => {
                                const option =
                                  fluVaccineOptions[keyName].valueCoding;
                                return (
                                  <React.Fragment key={nanoid()}>
                                    {index !== 0 && <ListDivider />}
                                    <ListItem>
                                      <Radio
                                        id={option.code}
                                        value={option.code}
                                        checked={option.selected}
                                        label={
                                          language === "nl"
                                            ? option.displayNl
                                            : option.displayFr
                                        }
                                        onChange={() =>
                                          handleFluOptionChange(
                                            data,
                                            option.code
                                          )
                                        }
                                      />
                                    </ListItem>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </List>
                        </RadioGroup>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="center">
                      <Segment
                        raised={covidVaccineActiveDisplay}
                        disabled={!covidVaccineActiveDisplay}
                        color={covidVaccineActiveDisplay ? "green" : "grey"}
                      >
                        <Icon
                          name={covidVaccineActiveDisplay ? "check" : "cancel"}
                          size="big"
                          className={
                            covidVaccineActiveDisplay ? "check-icon" : null
                          }
                        />{" "}
                        {covidVaccineUnderTitle}
                        <Divider />
                        <RadioGroup
                          className={
                            !covidVaccineActiveDisplay
                              ? "disabled-component"
                              : null
                          }
                          aria-labelledby="example-payment-channel-label"
                          overlay
                          name="example-payment-channel"
                        >
                          <List
                            className="text-align-left"
                            component="div"
                            variant="outlined"
                            orientation="vertical"
                            sx={{
                              borderRadius: "sm",
                              boxShadow: "sm",
                            }}
                          >
                            {Object.keys(covidVaccineOptions).map(
                              (keyName, index) => {
                                const option =
                                  covidVaccineOptions[keyName].valueCoding;
                                return (
                                  <React.Fragment key={nanoid()}>
                                    {index !== 0 && <ListDivider />}
                                    <ListItem>
                                      <Radio
                                        id={option.code}
                                        value={option.code}
                                        checked={option.selected}
                                        label={
                                          language === "nl"
                                            ? option.displayNl
                                            : option.displayFr
                                        }
                                        onChange={() =>
                                          handleCovidOptionChange(
                                            data,
                                            option.code
                                          )
                                        }
                                      />
                                    </ListItem>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </List>
                        </RadioGroup>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          )}

          {loading ? (
            <PlaceHolderLoader type={"double"} language={language} />
          ) : (
            <Card fluid>
              <Card.Content>
                <Card.Header className="card-header" textAlign="left">
                  {dispensationsTitle}

                  <Button
                    floated="right"
                    basic
                    circular
                    icon
                    size="tiny"
                    onClick={() => dispatch(toggleCard(openCard))}
                    aria-label="Toggle card button"
                  >
                    <Icon name={openCard ? "angle down" : "angle left"} />
                  </Button>
                </Card.Header>
              </Card.Content>
              {openCard ? renderDispensations(dispensations) : null}
            </Card>
          )}
        </Card.Group>
        <Button
          className="submit-button"
          primary
          floated="right"
          loading={loading || submitting}
          content={language === "nl" ? "Verzend" : "Envoyer"}
          disabled={!fluVaccineActiveDisplay && !covidVaccineActiveDisplay}
          icon="send"
          labelPosition="right"
          onClick={() => handleSubmit(data)}
          aria-label={language === "nl" ? "Verzend" : "Envoyer"}
        />
      </Container>

      {renderFooter()}
    </div>
  );
};

export default App;

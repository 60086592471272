import useFhirClient from "./useFhirClient";
import { useEffect, useState } from "react";
import parseJwt from "../tools/parseJwt";

function useBearer() {
  const [language, setLanguage] = useState();
  const [pharmacyNihii, setPharmacyNihii] = useState();
  const [accessToken, setAccessToken] = useState();
  const { fhirClient, error } = useFhirClient();

  useEffect(() => {
    console.log(fhirClient);
    const getBearer = async () => {
      if (fhirClient && fhirClient.state.tokenResponse.access_token && !error) {
        const bearerToken = fhirClient.state.tokenResponse.access_token;
        sessionStorage.setItem("bearer", bearerToken);
        const jsonPayload = parseJwt(bearerToken);
        setLanguage(jsonPayload.language.toLowerCase());
        setAccessToken(bearerToken);
        setPharmacyNihii(jsonPayload.PharmacyNihii);
        setLanguage(jsonPayload.language.toLowerCase());
      }
    };

    getBearer();
  }, [fhirClient, error]);

  return { language, pharmacyNihii, accessToken };
}

export default useBearer;

import { ResponsivePie } from '@nivo/pie'
import useBearer from "../../hooks/useBearer";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ResoponsivePieChart = ({ data }) => {
    const { language } = useBearer();

    function CenteredMetric({centerX, centerY}) {

        return (
            <g>
                <circle cx={centerX} cy={centerY} r="40"  fill="#DD7A30" />
                <text
                    x={centerX}
                    y={centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill="white"
                    fontSize="0.85em"
                    fontFamily="Roboto, sans-serif">
                    {language === 'nl' ? 'Risico' : 'Risque'}</text>
            </g>
        );
    }

    return (
    <ResponsivePie
        data={data}
        margin={{ top: 25, right: 10, bottom: 25, left: 10 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={0}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        enableArcLabels={false}
        arcLinkLabel={datum => language === 'nl' ? datum.data.textNl : datum.data.textFr}
        arcLinkLabelsColor={datum => datum.data.risk ? '#DD7A30' : '#2285CF'}
        colors={datum => datum.data.risk ? '#DD7A30' : '#2285CF'}
        layers={["arcs", "arcLabels", "arcLinkLabels", CenteredMetric]}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLabelsSkipAngle={10}
        tooltip={() => (<></>)}
        width={600}
        height={220}
    />
)};
export default ResoponsivePieChart
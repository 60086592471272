import React from "react";
import { Button, Popup } from "semantic-ui-react";
import languageMap from "../../tools/languageMap";
export const InfoButton = ({ url, disabled, loading, language }) => {
  return (
    <Popup
      trigger={
        <Button
          style={{ float: "right" }}
          color="green"
          size="medium"
          circular
          icon="info"
          loading={loading}
          disabled={disabled}
          onClick={() => {
            window.open("//" + url, "_blank", "noreferrer");
          }}
          aria-label="Info button"
        />
      }
      content={languageMap["infoButtonPopupContent"][language]}
      position="bottom center"
    />
  );
};

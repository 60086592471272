import FHIR from 'fhirclient';
import { useEffect, useState } from 'react';

function useFhirClient() {
    const [ fhirClient, setFhirClient ] = useState();
    const [ error, setError ] = useState(false);
    
    useEffect(() => {
        FHIR.oauth2.ready()
            .then(client => setFhirClient(client))
            .catch(err => {
                setError(err);
            });
    }, []);

    return { fhirClient, error };
}

export default useFhirClient;
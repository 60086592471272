import { Container, Grid } from 'semantic-ui-react';
import './Footer.css';

function Footer({ leftContent, rightContent }) {
    return (
        <footer className='footer'>
                <Container textAlign='left'>
                    <Grid className='footer-grid' columns='equal'>
                        <Grid.Row>
                            <Grid.Column className='footer-content'>
                                {leftContent}
                            </Grid.Column>
                            <Grid.Column className='footer-content'>
                                {rightContent}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
        </footer>
    );
}

export default Footer;
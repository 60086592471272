import React from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { closeModal } from "../../features/app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import languageMap from "../../tools/languageMap";

function FFModal({ language }) {
  const dispatch = useDispatch();

  const openModal = useSelector((state) => state.openModal);
  const modalType = useSelector((state) => state.modalType);
  const modalHeader = useSelector((state) => state.modalHeader);
  const modalContent = useSelector((state) => state.modalContent);

  return (
    <div>
      <Modal
        className="ff-modal"
        dimmer="blurring"
        size="tiny"
        open={openModal}
        onClose={() => dispatch(closeModal())}
        centered
      >
        <Modal.Header style={{ textAlign: "center" }}>
          {modalType === "info" ? (
            <Icon
              name="check circle"
              size="big"
              className="check-icon margin-bottom-10"
            />
          ) : (
            <Icon
              name="remove circle"
              size="big"
              color="red"
              className="margin-bottom-10"
            />
          )}
          <br />
          {modalHeader}
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center" }}>
          {modalContent}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button positive onClick={() => dispatch(closeModal())}>
            {languageMap["okButton"][language]}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default FFModal;

import { Container, Menu, Image } from "semantic-ui-react";
import "./Navbar.css";

function Navbar({ title, image }) {
  return (
    <Menu className="navbar" color="green" inverted borderless={true}>
      <Container>
        <Menu.Item header className="header">
          {image ? (
            <Image
              alt="Image of Freddy Flux"
              src={image}
              size="tiny"
              className="image"
              width="75px"
              height="75px"
            />
          ) : null}
          {title ? title : null}
        </Menu.Item>
      </Container>
    </Menu>
  );
}

export default Navbar;

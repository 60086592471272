import React from "react";
import { Message } from "semantic-ui-react";
const DynamicMessage = ({ header, content, color, icon }) => {
  return (
    <Message
      className="message-box"
      color={color}
      icon={icon}
      size="small"
      header={header}
      content={content}
    />
  );
};

export default DynamicMessage;
